body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(135deg, #4facfe, #00f2fe);
  color: white;
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.container {
  animation: fadeIn 2s ease-in-out;
  position: relative;
}

.title {
  font-size: 4rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: bounceIn 1.5s ease-in-out;
}

.subtitle {
  font-size: 2rem;
  margin-top: 1rem;
  font-weight: 500;
  animation: fadeInUp 2s ease-in-out;
  color: rgba(255, 255, 255, 0.9);
}

/* Add floating emojis */
.emoji {
  font-size: 2rem;
  position: absolute;
  animation: float 5s infinite ease-in-out;
  opacity: 0.8;
}

.emoji:nth-child(1) {
  top: 10%;
  left: 20%;
  animation-delay: 0s;
}

.emoji:nth-child(2) {
  top: 30%;
  left: 70%;
  animation-delay: 1s;
}

.emoji:nth-child(3) {
  top: 60%;
  left: 40%;
  animation-delay: 2s;
}

.emoji:nth-child(4) {
  top: 80%;
  left: 10%;
  animation-delay: 3s;
}

.emoji:nth-child(5) {
  top: 50%;
  left: 90%;
  animation-delay: 4s;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3) translateY(-100px);
  }
  50% {
    opacity: 1;
    transform: scale(1.05) translateY(10px);
  }
  70% {
    transform: scale(0.95) translateY(-5px);
  }
  100% {
    transform: scale(1) translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}